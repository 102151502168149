import { HttpClient } from '@angular/common/http';
import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { sideMenuSchoolStructureItem } from '@layout/layout.component';
import { IResponse } from '@shared/interfaces';
import { ICompany } from '@shared/interfaces/company.interface';
import { UserSettingPayload } from '@shared/interfaces/user-setting.interface';
import {
  StructureDepth,
  findAllCampuses,
  findAllCompanies,
  findAllSchools,
  findSchoolStructureEntity,
  mapCompaniesWithChildren,
  mergeChildren,
} from '@shared/utils/school-structure';
import { catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SchoolStructureScopeService {
  BE_API_BASE_URL = environment.BE_API_BASE_URL;

  // scoped school structure entities till CLASS for logged in user (default)
  private _userScopedSchoolStructure = signal<sideMenuSchoolStructureItem[]>(
    [],
  );
  userScopedSchoolStructure = this._userScopedSchoolStructure.asReadonly();

  private _isStructureSelectionSaved = signal<boolean>(false);
  isStructureSelectionSaved = this._isStructureSelectionSaved.asReadonly();

  // scoped school structure entities till SCHOOL for logged in user
  private _userScopedSchoolStructureTillSchool = signal<
    sideMenuSchoolStructureItem[]
  >([]);
  userScopedSchoolStructureTillSchool =
    this._userScopedSchoolStructureTillSchool.asReadonly();

  readonly isSchoolStructureEmpty = computed(() => {
    const currScope = this._userScopedSchoolStructure();
    return currScope.length === 0;
  });
  // TODO: remove after hes-table with default scope refactor
  schoolsList = computed(() => {
    const currScope = this._userScopedSchoolStructure();
    return findAllSchools(currScope).map((school) => ({
      value: school.id,
      displayedValue: school.name,
    }));
  });

  // TODO: remove after hes-table with default scope refactor
  campusList = computed(() => {
    const currScope = this._userScopedSchoolStructure();
    return findAllCampuses(currScope).map((school) => ({
      value: school.id,
      displayedValue: school.name,
    }));
  });

  // TODO: remove after hes-table with default scope refactor
  companyList = computed(() => {
    const currScope = this._userScopedSchoolStructure();
    return findAllCompanies(currScope).map((school) => ({
      value: school.id,
      displayedValue: school.name,
    }));
  });

  // current selected school structure entity
  private _selectedSchoolStructureItem =
    signal<sideMenuSchoolStructureItem | null>(
      JSON.parse(localStorage.getItem('selectedSchoolStructureItem') ?? 'null'),
    );
  public selectedSchoolStructureItem =
    this._selectedSchoolStructureItem.asReadonly();

  public selectedSchoolId = computed(() => {
    return this.selectedSchoolStructureItem()?.type === 'school'
      ? this.selectedSchoolStructureItem()?.id
      : null;
  });

  private http = inject(HttpClient);

  constructor() {
    effect(() => {
      localStorage.setItem(
        'selectedSchoolStructureItem',
        JSON.stringify(this.selectedSchoolStructureItem()),
      );
      const userData = JSON.parse(localStorage.getItem('user') ?? 'null');
      if (userData) {
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...userData,
            settings: {
              selectedTargetId: this.selectedSchoolStructureItem()?.id,
              selectedTargetType: this.selectedSchoolStructureItem()?.type,
            },
          }),
        );
      }
    });
  }

  getAllCompanies() {
    return this.http.get<IResponse<ICompany[]>>(
      `${this.BE_API_BASE_URL}/companies/`,
    );
  }

  setStructureSelectionSaved(save: boolean) {
    this._isStructureSelectionSaved.set(save);
    this.setUserSetting();
  }

  setUserSetting() {
    const selectedTargetType =
      this.selectedSchoolStructureItem()?.type.includes('company')
        ? 'company'
        : this.selectedSchoolStructureItem()?.type;
    const userSetting: UserSettingPayload = {
      clearStructureSelection: !this.isStructureSelectionSaved(),
      selectedTargetId: this.selectedSchoolStructureItem()?.id,
      selectedTargetType,
    };
    return this.http
      .put(`${this.BE_API_BASE_URL}/user-settings`, userSetting)
      .subscribe();
  }

  populateDefaultScope() {
    const userData = JSON.parse(localStorage.getItem('user') ?? 'null');
    const settings = userData?.settings;
    let seStructureItemCallback: undefined | ((item: any) => void) = undefined;
    if (settings?.selectedTargetId && settings?.selectedTargetType) {
      this.setStructureSelectionSaved(true);
      seStructureItemCallback = (item) => {
        if (
          item.id === settings.selectedTargetId &&
          item.type.includes(settings.selectedTargetType)
        ) {
          this._selectedSchoolStructureItem.set(item);
        }
      };
    }
    return this.getAllCompanies().pipe(
      catchError((error) => {
        if (error.status === 404) {
          return of({
            data: [],
          });
        }
        throw error;
      }),
      map((res) => {
        this._userScopedSchoolStructure.set(
          mergeChildren(
            mapCompaniesWithChildren(res.data, StructureDepth.CLASS),
            seStructureItemCallback,
          ),
        );
        this._userScopedSchoolStructureTillSchool.set(
          mergeChildren(
            mapCompaniesWithChildren(res.data, StructureDepth.SCHOOL),
          ),
        );
        return res.data;
      }),
    );
  }

  updateSelectedStructure(item: sideMenuSchoolStructureItem | null) {
    if (item === null) this._selectedSchoolStructureItem.set(null);
    else {
      const schoolStructureItemTillClass = findSchoolStructureEntity(
        this._userScopedSchoolStructure(),
        item.type,
        item.id,
      );
      this._selectedSchoolStructureItem.set(schoolStructureItemTillClass);
    }
    this.setUserSetting();
  }

  getUserScopedSchoolStructureTillDepth(
    depth: StructureDepth,
    items: sideMenuSchoolStructureItem[] = this.userScopedSchoolStructure(),
  ): sideMenuSchoolStructureItem[] {
    return items
      .filter((item) => item.depth <= depth)
      .map((item) => ({
        ...item,
        children: this.getUserScopedSchoolStructureTillDepth(
          depth,
          item.children,
        ),
      }));
  }
}
